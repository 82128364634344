import { badgeStyles } from "@components/ui/product-badges/styles";
import type { Badge } from "@components/ui/product-badges/utils";
import {
    badgesStyles,
    formatBadges,
    ProductBadgeVariant,
} from "@components/ui/product-badges/utils";
import { CommonCMS } from "@lib/constants/contentful";
import { useMicrocopy } from "@lib/contentful/microcopy/MicrocopyContext";
import { Tag } from "@ui/components/data-display/tag/Tag";
import { Flex } from "@ui/components/layout/flex/Flex";
import type { FC } from "react";
import { useMemo } from "react";
import { space } from "ui/src/design-tokens";

export const BADGES_CONTAINER_TEST_ID = "badges-container";
export const DISCOUNT_BADGE_TEST_ID = "discount";

export interface ProductBadgesProps {
    discount?: string;
    customBadges?: string[];
    isOnProductTile?: boolean;
}

export const ProductBadges: FC<ProductBadgesProps> = ({
    discount,
    customBadges,
    isOnProductTile = true,
}) => {
    const { getMultiple } = useMicrocopy();

    const microcopies = useMemo(
        () =>
            getMultiple(CommonCMS.page, [
                CommonCMS.salesDays.blackFriday,
                CommonCMS.salesDays.cyberMonday,
            ]),
        []
    );

    const discountBadge: Badge = discount && {
        name: discount,
        type: ProductBadgeVariant.DEFAULT,
        testId: DISCOUNT_BADGE_TEST_ID,
    };
    const badges = useMemo((): Badge[] => {
        const formattedBadges = formatBadges(customBadges, microcopies);
        return discountBadge ? [discountBadge, ...formattedBadges] : formattedBadges;
    }, [customBadges, discountBadge]);

    return (
        <Flex
            alignItems={"center"}
            gap={space["0.2"]}
            flexWrap={"wrap"}
            data-testid={BADGES_CONTAINER_TEST_ID}
            sx={{
                ...(isOnProductTile
                    ? { position: "absolute", top: [3, 4], left: [3, 4, 4, 6], right: 10 }
                    : {}),
            }}
        >
            {badges?.map?.((badge, index) => {
                return (
                    <Tag
                        key={`badge-${badge.name}-${index}`}
                        data-testid={`badge-${badge.testId}`}
                        sx={{
                            ...badgeStyles,
                            ...badgesStyles[badge.type],
                        }}
                        display={index > 2 ? "none" : "block"}
                    >
                        {badge.name}
                    </Tag>
                );
            })}
        </Flex>
    );
};
